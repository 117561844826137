<template>
  <div v-if="true ">
    <div id="top-bar">
      <div class="sidebar" id="side-nav">

      <span class="material-icons" @click="this.closeNav()" style="display: flex;justify-content: end;">
        close
      </span>

        <div class="federations">
          Filter by Federations
          <div v-for="(item,key) in this.federations" v-bind:key="item">

            <a :href="'/day/'+this.date+'?isBackup='+this.isBackup+'&federation='+key.toLowerCase()"
               style="text-decoration: none;" class="sidebar-federations">
              <div class="federation">{{ key }}</div>
              <div class="games">{{ item }}</div>
            </a>
          </div>
        </div>
        <div style="padding-top: 20px"></div>

        <div class="federations">
          Filter by Competition
          <div v-for="(item,key) in this.competition_names" v-bind:key="item">
            <a :href="'/day/'+this.date+'?isBackup='+this.isBackup+'&competition_name='+key.toLowerCase()"
               style="text-decoration: none;" class="sidebar-federations">
              <div class="federation">{{ key }}</div>
              <div class="games">{{ item }}</div>
            </a>
          </div>
        </div>
      </div>
      <div class="top-menu" id="top-menu">
        <span @click="this.openNav()" style="display: flex;justify-content: start;padding-left: 20px"
              class="material-icons">menu</span>
      </div>

      <div id="main">
        <div id="entry">
          <div class="tabs">
            <div v-for="(date,index) in formattedDates" v-bind:key=date>
              <div id="date" class="tabs-component-tab-a-active" v-if='dates[index]=== this.$route.params["day"]'>

                <a :href="'/day/'+dates[index]+'?isBackup='+backup[index]"
                   class="tabs-component-tab tabs tabs-component-tab-a">
                  {{ date }}
                </a>
              </div>
              <div id="date" v-else>

                <a :href="'/day/'+dates[index]+'?isBackup='+backup[index]"
                   class="tabs-component-tab tabs tabs-component-tab-a">
                  {{ date }}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div id="dddf">


          <div style="height: 10px;background-color: white">

          </div>
          <a href="https://www.wasafibet.co.tz/">
            <img src="https://odibets.s3.eu-west-1.amazonaws.com/img/promos/happyhour-tz.jpeg"
                 style="height: 210px;width: 99%;border-radius: 10px;"
                 alt="An image of Diamond advertising for Wasafi bets"/></a>
          <!--      <div style="height: 10px;background-color: white"></div>-->

        </div>
      </div>
    </div>
    <div id="entry">
    <div v-if="Object.keys(games).length===0 && loaded">
      <img src="https://s3-eu-west-1.amazonaws.com/odibets/img/i-404@2x.png">

      <div style="font-family: 'Rubik', sans-serif;padding-bottom: 80%">
        <div style="padding-bottom: 30px">Boss hizo games hatuna...</div>
        <div>
          <a href="/" style="text-decoration: none;">
            <span style="background-color: #4f23ee;color: white;padding: 10px"> Return to home page</span>
          </a>
        </div>
      </div>

      <div>

      </div>
    </div>
      <div v-else v-for="(federations) in games" v-bind:key="federations">

        <div v-for="(competition) in federations" v-bind:key="competition" class="league">
          <div class="league-header">

            <span class="league-competition">
          {{ competition[0]["competition_name"] }}
        </span>
            <span style="padding-right: 12px;">

              <img :src="flags[competition[0]['competition_cluster']]" loading="lazy" alt="" width="35"
                   class="country-image"/>
        </span>

          </div>
          <div>
            <div v-if="this.backup===true ">
              <div style="text-decoration: none;color: black" v-for="game in competition" v-bind:key="game">
                <div class="single-game">

                <span class="game-time">{{
                    (parseInt(game["start_date"].split("T")[1].substring(0, 2)) + 2).toString() + game["start_date"].split("T")[1].substring(2, 5)
                  }}</span>
                  <div class="match-versus">
                    <div style="text-align: end;width: 30%">{{ game["home_team"] }}</div>
                    <div style="width: 10%;padding-right: 10px">-</div>
                    <div style="width: 40%;text-align: start;">{{ game["away_team"] }}</div>
                  </div>
                  <div class="prediction" v-if="game['status']==='pending'">
                    {{ game["prediction"] }}
                  </div>
                  <div class="prediction prediction-won" v-if="game['status']==='won'">
                    {{ game["prediction"] }}
                  </div>
                  <div class="prediction prediction-lost" v-if="game['status']==='lost'">
                    {{ game["prediction"] }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <router-link style="text-decoration: none;color: black" :to="'/game/'+game['id']"
                           v-for="game in competition" v-bind:key="game" class="single-game">
                <div style="padding-top: 12px">

                  <span class="game-time">{{
                      (parseInt(game["start_date"].split("T")[1].substring(0, 2)) + 2).toString() + game["start_date"].split("T")[1].substring(2, 5)
                    }}</span>
                </div>
                <div class="match-versus">
                  <div style="text-align: end;width: 30%">{{ game["home_team"] }}</div>
                  <div style="width: 10%;padding-right: 10px">-</div>
                  <div style="width: 40%;text-align: start;">{{ game["away_team"] }}</div>
                </div>
                <div class="prediction" v-if="game['status']==='pending'">
                  {{ game["prediction"] }}
                </div>
                <div class="prediction prediction-won" v-if="game['status']==='won'">
                  {{ game["prediction"] }}
                </div>
                <div class="prediction prediction-lost" v-if="game['status']==='lost'">
                  {{ game["prediction"] }}
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <BottomBanner></BottomBanner>
    </div>
  </div>
  <div v-else style="height: 100%">
    <div class="loader"></div>
  </div>

</template>

<script>


const HOST = "https://api.wasafibettips.com/";

//const HOST = "http://127.0.0.1:8888";
import axios from "axios";
import {DateTime} from "luxon";
import BottomBanner from "@/components/BottomBanner";

export default {
  name: "GamesInDay",
  components: {BottomBanner},
  data() {
    return {
      date: "",
      isToday:false,
      isBackup: false,
      federation: "",
      competition: "",
      // haa don't do null kids
      games: {},
      // haa do some
      flags: {},
      federations: {},
      competition_names: {},

      // tabs

      // haa don't do null kids
      dates: null,
      backup: null,
      formattedDates: null

    }
  },

  mounted() {
    this.createTabs();
    this.id = this.$route.path.toLowerCase();

    this.date = this.$route.params["day"];
    this.isBackup = this.$route.query['isBackup'] === "true";

    if ('federation' in this.$route.query) {
      this.federation = this.$route.query['federation'];
    }
    if ('competition_name' in this.$route.query) {
      this.competition = this.$route.query['competition_name'];
    }


    if (this.id === "/") {
      this.isToday=true
      const now = DateTime.now();

      let t = now.month.toString().padStart(2, "0") + "-" + now.day.toString().padStart(2, "0");

      this.date = now.year + "-" + t;

      this.isBackup = false;
    }
    let tv = this.isBackup ? "backup=1" : "predictions=1";

    let cmp = this.competition !== "" ? "&competition_name=" + this.competition.replace(" ", "%20") : ""


    let fed = this.federation !== "" ? "&federation=" + this.federation : ""

    axios.get(HOST + "/v1/endpoint?" + tv + "&date=" + this.date + cmp + fed)
        .then(response => {
          console.log(response.config.url);
          this.games = response.data;
          for (const i in response.data) {
            for (const j in response.data[i]) {
              let country = response.data[i][j][0]["competition_cluster"];
              let host = "https://restcountries.com/v3.1/name/" + String(response.data[i][j][0]["competition_cluster"]).toLowerCase() + "?fields=flags";
              axios.get(host).then(response => {
                this.flags[country] = response.data[0]["flags"]["png"];
              })
            }
          }
          this.loaded = false;

        });

    // fetch federations to display in the side menu
    axios.get(HOST + "/v1/endpoint?federations=1").then(response => {
      this.federations = response.data;
    })

    // fetch federations to display in the side menu
    axios.get(HOST + "/v1/endpoint?competition_names=1").then(response => {
      this.competition_names = response.data;
    })
  },
  methods: {

    openNav() {
      document.getElementById("side-nav").style.width = "250px";
      document.getElementById("main").style.marginLeft = "250px";
      document.getElementById("top-menu").style.display = "none";

      document.getElementById("entry").style.display = "none";

      document.getElementById("dddf").style.display = "none";
    },

    closeNav() {

      document.getElementById("side-nav").style.width = "0";
      document.getElementById("main").style.marginLeft = "0";
      document.getElementById("top-menu").style.display = "block";

      document.getElementById("entry").style.display = "block";

      document.getElementById("dddf").style.display = "block";
    },
    createTabs() {
      let day = DateTime.now().minus({day: 3});

      let today = DateTime.now();

      let dates = [];
      let backup = [];
      let formattedDates = [];
      for (let i = 0; i < 5; i++) {
        let curr_day = day.plus({day: i});
        let t = curr_day.month.toString().padStart(2, "0") + "-" + curr_day.day.toString().padStart(2, "0");
        dates.push(curr_day.year + "-" + t)

        if (curr_day.day === today.day) {
          formattedDates.push("Today");
        } else if (curr_day.day === today.plus({day: 1}).day) {

          formattedDates.push("Tomorrow");
        } else {
          formattedDates.push(curr_day.weekdayShort)

        }
        if (curr_day.day >= today.day) {
          backup.push(false)
        } else {
          backup.push(true)
        }
      }
      this.dates = dates;
      this.backup = backup;
      this.formattedDates = formattedDates;

    },
    getCountryFlag(info) {
      let HOST = "https://restcountries.com/v3.1/name/" + String(info["competition_cluster"]).toLowerCase() + "?fields=flags";
      // eslint-disable-next-line no-unused-vars
      let tv = null;

      axios.get(HOST).then(
          response => {
            tv = response.data[0]["flags"]["png"]

          })

      return tv;
    }
  }
}

</script>

<style scoped>
.results {
  display: flex;
  justify-content: center;
}

#side-nav {
  width: 0;
  background-color: white;
}

#top-menu {
  width: 100%;
  background-color: white;
}

.sidebar {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  overflow-x: hidden; /* Disable horizontal scroll */
  /*transition: 1s;*/
}

.prediction {
  margin-top: 15px;
  margin-left: 17px;
  color: #2c3e50;
  font-size: 13px;
  display: flex;
  font-weight: bold;
  justify-content: center;

}

.league-header {
  display: flex;
  justify-content: space-between;
}

.sidebar-federations {
  color: black;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 15px;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
}

#entry {
  background-color: white !important;
}

.league {
  margin-top: 20px;
  background-color: rgba(0, 0, 0, 0.04);
  box-shadow: 7px 5px 5px 0 rgba(0, 0, 0, 0.075);
  color: rgb(0, 15, 38);
  text-align: start;
}

.loader {

  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #002f6c; /* Blue */
  border-radius: 50%;
  position: absolute;
  top: 44%;
  left: 44%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.single-game {
  display: flex;
  padding-bottom: 10px;
  background-color: white;
}

.single-game:hover {
  color: white;
  background-color: #3f3f3f;
}

.single-game:hover > * {
  color: white !important;
  background-color: #3f3f3f;
}

.single-game:hover .game-time {
  color: #f3f3f3;
}

.country-image {
  padding-top: 10px;
}

.league-competition {
  color: rgba(0, 0, 0, 0.75);
  font-weight: bold;
  font-family: 'Inter', sans-serif;

  font-size: 15px;
  padding: 10px 10px 15px;
}

#dddf {
  width: 100%;
  height: 230px;

}

#top-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

#entry {
  padding-top: 10px;
}

.tabs {
  display: flex;
  justify-content: space-evenly;
  list-style-type: none;
  margin-block-end: 0;
  padding-inline-start: 0;

}

.tabs-component-tab-a {
  text-decoration: none;
  color: white;
}

.tabs-component-tab {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 7px 10px;
  border-radius: 15px;
}
.tabs-component-tab:hover :active :hover :visited{
  border-radius: 15px;
  background: none;
}


.tabs-component-tab-a-active {
  background-color: #4f23ee;
  border-radius: 15px;
}

.is-active {
  background-color: #4f23ee;
  color: white;
}

.match-versus {
  padding-top: 15px;
  padding-left: 10px;
  font-family: 'Rubik', sans-serif;
  /*font-weight: bold;*/
  width: 70%;
  display: flex;
  font-size: 12px;
  justify-content: space-evenly;
  text-align: end;
}

.game-time {
  margin-left: 10px;
  padding-top: 10px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 12px;
  font-weight: bold;
  font-family: 'Inter', sans-serif;

}

.prediction-won {
  color: green;
  font-weight: bold;
}

.prediction-lost {
  color: red;
  font-weight: bold;
}
</style>