import { createApp } from 'vue'
import App from './App.vue'
import {Tabs, Tab} from 'vue3-tabs-component';
import router from './router'


createApp(App).use(router)
    .component("tabs",Tabs)
    .component("tab",Tab)
    .mount('#app')
