<template>
  <div v-if="loaded">

    <div class="timer-container">

      <img src="../assets/soccer.png" style="width: 100%; height: 20%" alt="A image of a green soccer field"/>
      <div class="centered">
        <div class="timer">
          <div class="single-time">
            <div><span class="timer-space">{{ days }}
          </span>
              <div class="timer-text">days</div>
            </div>
          </div>
          <div class="single-time">
            <span class="timer-space">{{ hours }}</span>
            <div class="timer-text">hours</div>

          </div>
          <div class="single-time">

            <span class="timer-space"> {{ minutes }}</span>
            <div class="timer-text">minutes</div>

          </div>
          <div class="single-time">
            <span class="timer-space">{{ seconds }}</span>
            <div class="timer-text">seconds</div>

          </div>
        </div>
      </div>
    </div>

    <div class="top-spacer">
      <span id="home_team"> {{ this.game["home_team"] }}</span>

      <span id="away_team">{{ this.game["away_team"] }}</span>
    </div>

    <div class="top-spacer">

      <div>
        <span v-for="(result) in this.home_team_results" v-bind:key="result">
          <span v-if="result==='W'" class="green prev-games"> {{ result }}</span>
          <span v-else-if="result==='D'" class="yellow prev-games" style="padding-left: 6px"> {{ result }}</span>
          <span v-else class="red prev-games" style="padding-left: 6px"> {{ result }}</span>
        </span>
      </div>
      <div>
        <div style="font-size: 13px">{{ this.game["competition_name"] }}</div>
        <div style="font-size: 11px">{{ this.game["competition_cluster"] }}
        </div>
      </div>

      <div>
        <span v-for="(result) in this.away_team_results" v-bind:key="result">
          <span v-if="result==='W'" class="green prev-games"> {{ result }}</span>
          <span v-else-if="result==='D'" class="yellow prev-games" style="padding-left: 6px"> {{ result }}</span>
          <span v-else class="red prev-games" style="padding-left: 6px"> {{ result }}</span>

        </span>
      </div>
    </div>
    <div>
      <div class="pv-headers"> Prediction</div>
      <div class="small-line"></div>

      <div style="margin-top: 15px">
       <span class="main-prediction green"
             v-if="this.loaded && this.game['prediction'].toString().padEnd(2,' ').startsWith('1')">{{
           this.game["prediction"].toString().padEnd(2, '&nbsp;')
         }}</span>
        <span class="main-prediction yellow"
              v-else-if=" this.loaded && this.game['prediction'].toString().padEnd(2,' ').startsWith('X')">{{
            this.game["prediction"].toString().padEnd(2, '&nbsp;')
          }}</span>
        <span class="main-prediction red" v-else>{{

            this.game["prediction"].toString().padEnd(2, "&nbsp;")
          }}</span>

      </div>
    </div>
    <div>
      <div class="pv-headers">
        Main Odds
      </div>
      <div class="small-line"></div>

      <div style="margin-top: 15px;display: flex">
       <span class="main-prediction green">{{
           this.game["odds"]["1"].toFixed(1)
         }}</span>
        <span class="main-prediction yellow">{{
            this.game["odds"]["X"].toFixed(1)
          }}</span>
        <span class="main-prediction red">{{ this.game["odds"]["2"].toFixed(1) }}</span>
      </div>
    </div>


    <div v-if="Object.keys(this.home_team).length!==0">
      <div class="pv-headers">
        Home Team Stats
      </div>

      <div style="text-align: start;margin-left: 5%;margin-right: 5%;font-size: 13px" class="team-stats">
        <div style="font-family: 'Inter', sans-serif;display: flex;justify-content: space-between;padding-top: 10px">
          <span> Team:</span>
          <span>{{ this.game["home_team"] }}
          </span>
        </div>
        <div style="border-bottom: 1px solid rgba(0,0,0,0.05);padding-bottom: 0!important;"></div>

        <div style="display: flex;justify-content: space-between;padding-top: 10px">
          <div style="display:flex;">
            <span>Games Played</span>

          </div>
          <span>{{ this.home_team["matches_played"] }}</span>
        </div>


        <div style="display: flex;justify-content: space-between">
          <div style="display: flex">
            <span style="width: 15px;height: 15px;background-color: green;margin-right:8px;border-radius: 50%"></span>
            <span>Games Won</span>
          </div>
          <span>{{ this.home_team["won"] }}</span>
        </div>

        <div style="display: flex;justify-content: space-between;">
          <div style="display: flex">
              <span
                  style="width: 15px;height: 15px;background-color: #ffb400;margin-right:8px;border-radius: 50%"></span>
            <span>Games Drawn</span>
          </div>
          <span>{{ this.home_team["draw"] }}</span>
        </div>

        <div style="display:flex;justify-content: space-between;">
          <div style="display:flex;">
            <span style="width: 15px;height: 15px;background-color: red;margin-right:8px;;border-radius: 50%"></span>
            <span>Games Lost</span>

          </div>
          <span>{{ this.home_team["lost"] }}</span>
        </div>
        <div style="font-family: 'Inter', sans-serif;display: flex;justify-content: space-between;padding-top: 3px">
          <span> Points:</span>
          <span>{{ this.home_team["points"] }}
          </span>
        </div>
        <div style="display: flex;border-radius: 4px">
          <div
              :style="'border-top-left-radius:5px;border-bottom-left-radius:5px;background-color:green;height:7px;width:'+((this.home_team['won']/this.home_team_total)*100)+'%;'">
          </div>
          <div
              :style="'background-color:#ffb400;height:7px;width:'+((this.home_team['draw']/this.home_team_total)*100)+'%;'">
          </div>

          <div
              :style="'border-top-right-radius:5px;border-bottom-right-radius:5px;background-color:red;height:7px;width:'+((this.home_team['lost']/this.home_team_total)*100)+'%;'">
          </div>

        </div>
      </div>

    </div>
    <div v-if="Object.keys(this.away_team).length!==0">
      <div class="pv-headers">
        Away Team Statistics
      </div>

      <div style="text-align: start;margin-left: 5%;margin-right: 5%;font-size: 13px" class="team-stats">

        <div style="font-family: 'Inter', sans-serif;display: flex;justify-content: space-between;padding-top: 10px">
          <span> Team:</span>
          <span>{{ this.game["away_team"] }}
          </span>
        </div>
        <div style="border-bottom: 1px solid rgba(0,0,0,0.05);padding-bottom: 0!important;"></div>
        <div style="display: flex;justify-content: space-between;padding-top: 10px">
          <div style="display:flex;">
            <span>Games Played</span>

          </div>
          <span>{{ this.away_team["matches_played"] }}</span>
        </div>

        <div style="display: flex;justify-content: space-between">
          <div style="display:flex;">
              <span
                  style="width: 15px;height: 15px;background-color: green;margin-right:8px;;border-radius: 50%"></span>
            <span>Games Won</span>

          </div>
          <span>{{ this.away_team["won"] }}</span>
        </div>

        <div style="display: flex;justify-content: space-between;">
          <div style="display:flex;">
              <span
                  style="width: 15px;height: 15px;background-color: #ffb400;margin-right:8px;border-radius: 50%"></span>
            <span>Games Drawn</span>

          </div>
          <span>{{ this.away_team["draw"] }}</span>
        </div>

        <div style="display:flex;justify-content: space-between;">
          <div style="display:flex;">
            <span style="width: 15px;height: 15px;background-color: red;margin-right:8px;border-radius: 50%"></span>
            <span>Games Lost</span>

          </div>
          <span>{{ this.away_team["lost"] }}</span>
        </div>

        <div style="font-family: 'Inter', sans-serif;display: flex;justify-content: space-between;padding-top: 5px">
          <span> Points:</span>
          <span>{{ this.away_team["points"] }}
          </span>
        </div>

        <div style="display: flex;border-radius: 4px">
          <div
              :style="'border-top-left-radius:5px;border-bottom-left-radius:5px;background-color:green;height:7px;width:'+((this.away_team['won']/this.away_team_total)*100)+'%;'">
          </div>
          <div
              :style="'background-color:#ffb400;height:7px;width:'+((this.away_team['draw']/this.away_team_total)*100)+'%;'">
          </div>

          <div
              :style="'border-top-right-radius:4px;border-bottom-right-radius:5px;background-color:red;height:7px;width:'+((this.away_team['lost']/this.away_team_total)*100)+'%;'">

          </div>

        </div>

      </div>
    </div>

    <div class="previous-encounters">


      <div style="display:flex;justify-content: space-between">
        <div class="pv-headers">
          Previous Encounters
        </div>
        <div v-if="show_previous_encounters">
          <button class="hth-button" @click=" show_previous_encounters= !show_previous_encounters">
            <span class="material-icons">expand_less</span>
          </button>
        </div>
        <div v-else>

          <button class="hth-button" @click=" show_previous_encounters = !show_previous_encounters"> <span
              class="material-icons">expand_more
</span></button>
        </div>
      </div>

      <div class="small-line"></div>
      <div style="padding-bottom: 10px"></div>
      <Transition name="hth">
        <div class="pv-matches" v-if="show_previous_encounters">
          <div class="single-pv-match" v-for="single in head_to_head" v-bind:key="single">
          <span style="width: 5%;">{{
              this.formatDate(single["start_date"])
            }}</span>
            <span style="width: 30%">{{ single["home_team"] }}</span> <span class="result">

          <span class="win" v-if="single['fulltime_result'].substring(0,1) > single['fulltime_result'].substring(4,5)">{{
              single["fulltime_result"].substring(0, 1)
            }}</span>
          <span class="draw"
                v-else-if="single['fulltime_result'].substring(0,1) === single['fulltime_result'].substring(4,5)">{{
              single["fulltime_result"].substring(0, 1)
            }}</span>

          <span class="lose" v-else>{{ single["fulltime_result"].substring(0, 1) }}</span>

        -
          <span class="lose"
                v-if="single['fulltime_result'].substring(0,1) > single['fulltime_result'].substring(4,5)">{{
              single["fulltime_result"].substring(4, 5)
            }}</span>
          <span class="draw"
                v-else-if="single['fulltime_result'].substring(0,1) === single['fulltime_result'].substring(4,5)">{{
              single["fulltime_result"].substring(4, 5)
            }}</span>

          <span class="win" v-else>{{ single["fulltime_result"].substring(4, 5) }}</span>

        </span> <span style="width: 30%">{{ single["away_team"] }}</span>
          </div>
        </div>
      </Transition>
    </div>

    <div style="display:flex;justify-content: space-between">
      <div class="pv-headers">
        Head To Head
      </div>
      <div v-if="show_head_to_head">
        <button class="hth-button" @click=" show_head_to_head = !show_head_to_head">
          <span class="material-icons">expand_less</span>
        </button>
      </div>
      <div v-else>

        <button class="hth-button" @click=" show_head_to_head = !show_head_to_head"> <span class="material-icons">
expand_more
</span></button>
      </div>
    </div>

    <div class="small-line"></div>
    <div style="padding-bottom: 10px"></div>

    <Transition name="hth">
      <div v-if="show_head_to_head">
        <div class="hth-team-versus">
          <div class="hth-teams">
            <div>
              {{ game["home_team"]}}
            </div>
            <div>
              {{ game["away_team"] }}
            </div>

          </div>
          <div>
            <div class="hth-info-title">
              Average bookie win chance
            </div>
            <div style="display: flex;justify-content: space-between;font-weight: normal">

              <div>
                <div style="background-color: rgba(0,0,0,0.2);width: 100px;height:7px;border-radius: 4px;display: flex">
                  <div
                      :style="'background-color:green;border-radius: 4px;width:'+head_to_head_stats['home_team']['avg_bookie_win_chance']*100+'px;' "></div>

                </div>
                <div style="padding-top: 5px">
                  {{ (head_to_head_stats["home_team"]["avg_bookie_win_chance"] * 100).toFixed(2) }}%
                </div>
              </div>
              <div>
                <div
                    style="background-color: rgba(0,0,0,0.2);width: 100px;height:7px;border-radius: 4px;display: flex;justify-content: end;text-align: end">

                  <div
                      :style="'background-color:green;border-radius: 4px;width:'+head_to_head_stats['away_team']['avg_bookie_win_chance']*100+'px;' "></div>
                </div>
                <div style="padding-top: 5px;text-align: end">
                  {{ (head_to_head_stats["away_team"]["avg_bookie_win_chance"] * 100).toFixed(2) }}%
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="hth-info-title">
              Average bookie draw chance
            </div>
            <div style="display: flex;justify-content: space-between;font-weight: normal">

              <div>
                <div style="background-color: rgba(0,0,0,0.2);width: 100px;height:7px;border-radius: 4px;display: flex">
                  <div
                      :style="'background-color:#ffb400;border-radius: 4px;width:'+head_to_head_stats['home_team']['avg_bookie_draw_chance']*100+'px;' "></div>

                </div>
                <div style="padding-top: 5px">
                  {{ (head_to_head_stats["home_team"]["avg_bookie_draw_chance"] * 100).toFixed(2) }}%
                </div>
              </div>
              <div>
                <div
                    style="background-color: rgba(0,0,0,0.2);width: 100px;height:7px;border-radius: 4px;display: flex;justify-content: end;text-align: end">

                  <div
                      :style="'background-color:#ffb400;border-radius: 4px;width:'+head_to_head_stats['away_team']['avg_bookie_draw_chance']*100+'px;' "></div>
                </div>
                <div style="padding-top: 5px;text-align: end">
                  {{ (head_to_head_stats["away_team"]["avg_bookie_draw_chance"] * 100).toFixed(2) }}%
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="hth-info-title">
              Average bookie lose chance
            </div>
            <div style="display: flex;justify-content: space-between;font-weight: normal">

              <div>
                <div style="background-color: rgba(0,0,0,0.2);width: 100px;height:7px;border-radius: 4px;display: flex">
                  <div
                      :style="'background-color:red;border-radius: 4px;width:'+head_to_head_stats['home_team']['avg_bookie_lose_chance']*100+'px;' "></div>

                </div>
                <div style="padding-top: 5px">
                  {{ (head_to_head_stats["home_team"]["avg_bookie_lose_chance"] * 100).toFixed(2) }}%
                </div>
              </div>
              <div>
                <div
                    style="background-color: rgba(0,0,0,0.2);width: 100px;height:7px;border-radius: 4px;display: flex;justify-content: end;text-align: end">

                  <div
                      :style="'background-color:red;border-radius: 4px;width:'+head_to_head_stats['away_team']['avg_bookie_lose_chance']*100+'px;' "></div>
                </div>
                <div style="padding-top: 5px;text-align: end">
                  {{ (head_to_head_stats["away_team"]["avg_bookie_lose_chance"] * 100).toFixed(2) }}%
                </div>
              </div>
            </div>
          </div>


          <div>
            <div class="hth-info-title">
              Clean sheet
            </div>
            <div style="display: flex;justify-content: space-between;font-weight: normal">

              <div>
                <div style="background-color: rgba(0,0,0,0.2);width: 100px;height:7px;border-radius: 4px;display: flex">
                  <div
                      :style="'background-color:red;border-radius: 4px;width:'+divByNum(head_to_head_stats['home_team']['clean_sheet'],home_team['matches_played'])*100+'px;' "></div>

                </div>
                <div style="padding-top: 5px">
                  {{ head_to_head_stats['home_team']['clean_sheet'] }} game(s) <br> out of {{
                    home_team['matches_played']
                  }}
                  games played

                </div>
              </div>
              <div style="width: 150px">
                <div
                    style="background-color: rgba(0,0,0,0.2);width: 100px;margin-left: 33%;height:7px;border-radius: 4px;display: flex;justify-content: end;text-align: end">

                  <div
                      :style="'background-color:red;border-radius: 4px;width:'+divByNum(head_to_head_stats['away_team']['clean_sheet'],away_team['matches_played'])*100+'px;' "></div>
                </div>
                <div style="padding-top: 5px;text-align: end">
                  {{ head_to_head_stats['away_team']['clean_sheet'] }} game(s)<br> out of {{
                    away_team['matches_played']
                  }}
                  games played
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="hth-info-title">
              Average goals Conceded
            </div>
            <div
                style="width: 100%;background-color: rgba(0,0,0,0.1);height: 8px;display: flex;justify-content: space-between;border-radius: 4px">
              <div
                  :style="'border-top-left-radius: 4px;border-bottom-left-radius:4px;background-color:green;width:'+divByNum(head_to_head_stats['home_team']['avg_goals_conceived'],this.avgGoalsConcededSum())*100+'%'"></div>

              <div
                  :style="'border-top-right-radius: 4px;border-bottom-right-radius:4px;background-color:red;width:'+divByNum(head_to_head_stats['away_team']['avg_goals_conceived'],this.avgGoalsConcededSum())*100+'%'"></div>
            </div>

          </div>
          <div style="display: flex;justify-content: space-between;font-weight: normal;padding-top: 10px">
            <div> {{ head_to_head_stats['home_team']['avg_goals_conceived'] }} goals</div>
            <div> {{ head_to_head_stats['away_team']['avg_goals_conceived'] }} goals</div>

          </div>

        </div>

      </div>
    </Transition>
  </div>
  <div v-else>
    <div class="loader"></div>
  </div>


</template>

<script>

import axios from "axios";
import {DateTime} from "luxon";
// import BarChart from "@/components/BarChart";

// const PREV_GAMES_HOST = "http://35.240.46.135:8888/v1/endpoint?prev_encounters=1&id="
const HOST = "https://api.wasafibettips.com/v1/endpoint?predictions=1";
const HEAD_TO_HEAD = "https://api.wasafibettips.com/v1/endpoint?head_to_head=1&id=";
const HOME_TEAM_HOST = "https://api.wasafibettips.com/v1/endpoint?home_team=1&id="
const AWAY_TEAM_HOST = "https://api.wasafibettips.com/v1/endpoint?away_team=1&id="
const PREV_GAMES_HOST = "https://api.wasafibettips.com/v1/endpoint?prev_encounters=1&id="

export default {
  name: "SingleGame",
  data() {
    return {
      id: "",
      game: {},
      hours: "00",
      minutes: "00",
      seconds: "00",
      days: "00",
      loaded: false,
      home_team: {},
      home_team_results: [],
      away_team_results: [],
      home_team_total: 0,
      away_team_total: 0,
      away_team: {},
      head_to_head: [],
      head_to_head_stats: {},

      // toggle instances
      show_head_to_head: false,
      show_previous_encounters: true,

    }
  },

  methods: {
    calculateDateDiff() {


      const date = DateTime.fromISO(this.game["start_date"])


      if (date > DateTime.now()) {
        const difference = date.diff(DateTime.now(), ["days", "hours", "minutes", "seconds"]).toObject();
        this.hours = (difference.hours + 2).toString().padStart(2, "0")
        this.minutes = difference.minutes.toString().padStart(2, "0");
        this.seconds = Math.floor(difference.seconds).toString().padStart(2, "0");
        this.days = Math.floor(difference.days).toString().padStart(2, "0");
      }
    },
    formatDate(date) {

      return DateTime.fromISO(date)
          .toLocaleString({month: 'short', year: 'numeric'})
          .replace(",", " ")
    },
    divByNum(num, div) {
      if (div === 0) {
        return num;
      }
      return (num / div);
    },
    avgGoalsConcededSum() {
      return this.head_to_head_stats["home_team"]["avg_goals_conceived"] + this.head_to_head_stats["away_team"]["avg_goals_conceived"];
    },
  },
  mounted() {
    // store game id
    // /game/191793

    this.id = this.$route.path.toLowerCase().substring(6);

    axios.get(HOST + "&id=" + this.id).then(
        response => {
          this.game = response.data

          this.loaded = true;

          this.calculateDateDiff();

          window.setInterval(() => {
            this.calculateDateDiff();
          }, 1000)
        }
    );
    axios.get(HEAD_TO_HEAD + this.id).then(
        response => {
          if (response.status===200) {
            this.head_to_head = response.data["encounters"];
            this.head_to_head_stats = response.data["stats"];

            this.show_head_to_head = true;
          }
        }
    );
    axios.get(HOME_TEAM_HOST + this.id).then(
        response => {
          this.home_team = response.data;
          this.home_team_total = response.data["won"] + response.data["lost"] + response.data["draw"]

          if (this.home_team_total === 0) {
            this.home_team_total = 1;
          }

        }
    );
    axios.get(AWAY_TEAM_HOST + this.id).then(
        response => {
          this.away_team = response.data;
          this.away_team_total = response.data["won"] + response.data["lost"] + response.data["draw"]
          if (this.away_team_total === 0) {
            this.away_team_total = 1;
          }
        }
    );
    axios.get(PREV_GAMES_HOST + this.id).then(
        response => {
          //  this.away_team = response.data;
          this.home_team_results = response.data["home_team_results"].toString().split("").slice(5, 10);

          this.away_team_results = response.data["away_team_results"].toString().split("").slice(5, 10);

        }
    );


  },
}

</script>

<style scoped>
.hth-button {
  text-decoration: none;
  background-color: unset;
  border: 1px rgba(0, 0, 0, 0.1) solid;
  border-radius: 50%;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 3px;
  margin-bottom: 3px;
  margin-top: 30px;

}

/* we will explain what these classes do next! */
.hth-enter-active,
.hth-leave-active {
  transition: opacity 0.5s ease;
}

.hth-enter-from,
.hth-leave-to {
  opacity: 0;
}

.hth-info-title {
  padding-top: 10px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  display: flex;
  justify-content: center;
}

.hth-team-versus {
  /*display: flex;*/
  padding-top: 10px;
  margin-left: 2%;
  margin-right: 2%;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  text-align: start;
  font-size: 12px;
}

.hth-teams {
  display: flex;
  padding-top: 5px;
  justify-content: space-between;

}

.loader {

  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #002f6c; /* Blue */
  border-radius: 50%;
  position: fixed;
  top: 43%;
  left: 43%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bottom-sheet {
  z-index: 800;
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  padding-left: 10px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  background-color: #002f6c;
  padding-bottom: 5px;
  padding-top: 5px;
}

@media screen and (max-width: 789px) {
  .bottom-sheet {
    left: 0
  }
}

.prev-games {
  margin: 4px;
  padding: 4px;
  font-size: 10px;
  letter-spacing: 1px;
  color: white;
  border-radius: 50%;
}

.single-pv-match:hover {
  color: white;
  background-color: #3f3f3f;
}

.single-pv-match:hover > * {
  box-shadow: unset;
  color: white !important;
}

.single-pv-match:hover .result {
  border: 0.5px rgba(255, 255, 255, 0.6) solid;

}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 55%;
  height: 45%;
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
}

.win {
  color: #42b983;
}

.lose {
  color: red;
}

.draw {
  color: black;
}

.single-pv-match:hover .draw {
  color: white;
}

.main-prediction {
  color: white;
  padding: 10px;
  font-size: 10px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  /*margin-left: 30px;*/
}

.green {
  background-color: green;
}

.yellow {
  background-color: #ffb400;
}

.red {
  background-color: red;
}

.single-pv-match {
  display: flex;
  justify-content: space-between;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  padding-left: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  text-align: start;
  margin-right: 10px;
}

.team-stats > * {
  padding-bottom: 10px;
}

.result {
  border-radius: 4px;
  box-shadow: 5px 0px 5px 1px #A7ADAF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  width: 10%;
  margin-right: 20px;
  max-width: 50px;
  /*!*position: absolute;*!*/
  /*left: 52%;*/
}

.top-spacer {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

#home_team {
  font-weight: bold;
  font-family: 'Inter', sans-serif;
}

#away_team {

  font-weight: bold;
  font-family: 'Inter', sans-serif;
}

/* Container holding the image and the text */
.timer-container {
  position: relative;
  text-align: center;
}

.timer-text {
  margin-top: 20px;
  font-size: 10px;
  text-align: center;
  color: black;
  text-transform: uppercase;
}

.pv-headers {
  padding-top: 40px;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 10px;

}

.small-line {
  border-bottom: solid 0.5px rgba(0, 0, 0, 0.1);
}

.timer-space {
  position: relative;
  background-color: rgba(0, 0, 0, 0.8);
  margin-left: 0px;
  color: white;
  padding: 7px !important;
}

.timer {
  position: absolute;
  top: 40%;
  /*left: 4%;*/
  display: flex;
  justify-content: space-evenly
}

.timer-text {
  margin-top: 10px;
  margin-left: 8px;
  font-size: 7px;
  text-align: center;
  display: flex;
  color: black;
  text-transform: uppercase;
}

.single-time {
  width: 50px;
}
</style>