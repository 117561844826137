import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import SingleGame from "@/views/SingleGame";
import GamesInDay from "@/views/GamesInDay";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: "/game/:id",
    name:"game",
    component: SingleGame
  },
  {
    path: "/day/:day",
    name:"day",
    component: GamesInDay
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
