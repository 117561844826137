<!--
Okay, directory layout
1. TABS
2. Dates
3. Games
4. -> each tab should deal with its own game
5
-->
<template>
    <GamesInDay></GamesInDay>
</template>

<script>
import GamesInDay from "@/views/GamesInDay";
export default {
  components: {GamesInDay}
}
</script>

<style scoped>

</style>